import React from "react";
export const spidelDateFormat = inputDate => {
  const date = new Date(inputDate);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const getLastWeek = () => {
  var today = new Date();
  var lastWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  ).getTime();
  return Math.floor(lastWeek / 1000);
};

export const getLastMonth = () => {
  var today = new Date();
  var lastWeek = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  ).getTime();
  return Math.floor(lastWeek / 1000);
};

export const getLastYear = () => {
  var today = new Date();
  var lastWeek = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  ).getTime();
  return Math.floor(lastWeek / 1000);
};

export const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
};

export function getUserDetails() {
  let user = localStorage.getItem("user");
  if (user && user !== "undefined") {
    return JSON.parse(user);
  } else {
    return {};
  }
}

export function isCancelItem(record) {
  return record.cancel ? "strikeout" : "";
}

export function pagerTitles() {
  return {
    prev: <span className="fa fa-angle-left pager-btn"></span>,
    first: <span className="fa fa-angle-double-left pager-btn"></span>,
    last: <span className="fa fa-angle-double-right pager-btn"></span>,
    next: <span className="fa fa-angle-right pager-btn"></span>
  };
}
