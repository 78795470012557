import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Logout from "../../images/logout.svg";
import "./index.scss";
const MENU = [
  {
    label: "Overview",
    value: "/"
  },
  {
    label: "Campaign",
    value: "/campaign"
  },
  {
    label: "Audience",
    value: "/audience"
  }
];

const Header = props => {
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);
  const history = useHistory();
  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };

  useEffect(() => {
    setActiveMenu(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className="header-menu-container">
      <div className="left-container ">
        <div className="menu-items">
          {MENU.map(menu => {
            return (
              <Link
                to={menu.value}
                className={activeMenu === menu.value ? "active-sidenav" : ""}
                onClick={() => setActiveMenu(menu.value)}
                key={menu.label}
              >
                {menu.label}
              </Link>
            );
          })}
        </div>
      </div>

      <div className="logout-container" onClick={logout}>
        <img src={Logout} className="logout-icon" alt="logout" />{" "}
        <span>Logout</span>
      </div>
    </div>
  );
};
export default Header;
