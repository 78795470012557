import React, { useState } from "react";
import ReactTooltip from 'react-tooltip'
import AppLogo from "../../images/logo.svg";
import AppLogoMin from "../../images/logo_min.svg";
import Campaign from "../../images/campaigns.svg";
import MenuOpen from "../../images/menu_open.svg";
import MenuClose from "../../images/menu_close.svg";
import "./index.scss";
const MENU = [
  {
    label: "Static Campaign",
    value: "/",
    iconName:"campaigns"
  },
  {
    label: "Dynamic Campaign",
    value: "/dynamic",
    iconName:"email"
  },
  {
    label: "Reports",
    value: "/dynamic",
    iconName:"report"
  }
];

const NavMenu = () => {
  const [openMenuFlag, seOpenMenuFlag] = useState(true);
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);  
  return (
    <div className="app-nav-menu-container">
      <div
        className="menu-open-close"
        onClick={() => seOpenMenuFlag(!openMenuFlag)}
      >
      
        <img
          src={openMenuFlag ? MenuOpen : MenuClose}
          alt="logo"
          className="menu-icon"
        />                
        {openMenuFlag ? <img src={AppLogo} alt="logo" className="logo-icon" /> : <img src={AppLogoMin} alt="logo" className="logo-min-icon" />} 
      </div>
      <div
        id="mySidenav"
        className={openMenuFlag ? "sidenav openmenu" : "sidenav"}
      >
        <div>
          {MENU.map(menu => {
            return (
              <div key={menu.label}>
                <a
                  // to={menu.value}
                  className={activeMenu === menu.value ? "active-sidenav" : ""}
                  // onClick={() => setActiveMenu(menu.value)}
                >
                  <div className="nav-container">
                    <img src={ menu.iconName ? require(`../../images/${menu.iconName}.svg`) :Campaign} alt="logo" className={`${menu.iconName} nav-icon`} data-tip={menu.label} />{" "}
                    {openMenuFlag && menu.label}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
      {!openMenuFlag && <ReactTooltip place="right"/>}
    </div>
  );
};
export default NavMenu;
