import { createStore, applyMiddleware, compose } from "redux";
import CRM_Reducer from "./redux/reducer";
import reduxThunk from "redux-thunk";
const preloadedState = {
  crmState: {}
};

export const store = createStore(
  CRM_Reducer,
  preloadedState,
  compose(applyMiddleware(reduxThunk))
);
