import React from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { SHOP_ENDPOINTS, DOMAIN_NAME } from "../../constants/endpoints";
import { invokeApi } from "../../services/dataServices";
import Spinner from "../../core-components/spinner";
import { toast } from "react-toastify";
import { clearOffllineData } from "../../utils/offlineService";
import LOGO from "../../images/logo.svg";
import "./index.scss";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDetails: {
        timezone: ""
      },
      redirectUrl: "",
      dataLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let element = document.getElementById("appHeaderBar");
    if (element) {
      element.style.display = "none";
    }
  }

  handleChange(e) {
    const { signUpDetails } = this.state;
    const { name, value } = e.target;
    this.setState({
      signUpDetails: {
        ...signUpDetails,
        [name]: value
      },
      [name + "Err"]: false
    });
  }

  errorCheck() {
    const errors = {};
    const { signUpDetails = {} } = this.state;
    if (
      signUpDetails.password &&
      (!signUpDetails.password || signUpDetails.password.length < 8)
    ) {
      errors["passwordErr"] = true;
    }
    if (signUpDetails.confirmPassword !== signUpDetails.password) {
      errors["confirmErr"] = true;
    }
    this.setState(errors);
    return Object.keys(errors).length > 0 ? false : true;
  }

  handleSubmit() {
    if (!this.errorCheck()) {
      return;
    }
    const { signUpDetails } = this.state;
    signUpDetails.timezone = "Asia/Calcutta";
    delete signUpDetails.confirmPassword;
    this.setState({ dataLoading: true });
    invokeApi(`${DOMAIN_NAME}${SHOP_ENDPOINTS.ADD_USER}`, "POST", signUpDetails)
      .then(data => {
        if (data.data) {
          toast.info("Sign Up Successfull");
          localStorage.removeItem("spidleAppToke");
          clearOffllineData("orderedItemsrestaurant");
          clearOffllineData("orderedItemstakeaway");
          clearOffllineData("tablesplits");
          toast.info("Please Veirfy account in " + signUpDetails.email);
          this.setState({
            redirectUrl: "login",
            dataLoading: false
          });
        } else {
          if (data.message) {
            toast.error(data.message);
          }
          if (data.errors) {
            data.errors.forEach(error => {
              toast.error(error.text && error.text.join(""));
            });
          }
          this.setState({
            dataLoading: false
          });
        }
      })
      .catch(() => this.setState({ dataLoading: false }));
  }

  render() {
    const { signUpDetails, redirectUrl, dataLoading } = this.state;
    if (redirectUrl === "login") {
      return <Redirect push to={`/${redirectUrl}`} />;
    }
    return (
      <div className="signup-container">
        <div className="signup-header-row">
          <img className="login-logo" src={LOGO} alt="app logo" />
        </div>
        <div className="signup-form">
          <div className="trail-msg">Start Your Free 20 days trial</div>
          <div className="title-conatiner">
            <h3 className="title">Sign Up</h3>
            <span className="create-label">
              <Link to="/login">or login</Link>
            </span>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="spidle-input-text"
              placeholder="First Name"
              name="first_name"
              value={signUpDetails.first_name}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="spidle-input-text"
              placeholder="Last Name"
              name="last_name"
              value={signUpDetails.last_name}
              onChange={this.handleChange}
            />
          </div>
          <div className={"form-group" + (false ? " has-error" : "")}>
            <input
              type="email"
              placeholder="Email"
              className="spidle-input-text"
              name="email"
              value={signUpDetails.email}
              onChange={this.handleChange}
            />
          </div>
          <div className={"form-group" + (false ? " has-error" : "")}>
            <input
              type="password"
              className="spidle-input-text"
              placeholder="Password   "
              name="password"
              value={signUpDetails.password}
              onChange={this.handleChange}
            />
            {this.state.passwordErr && (
              <div className="spidle-error-message">
                Required And Password must be atleast 8 characters
              </div>
            )}
          </div>
          <div className={"form-group" + (false ? " has-error" : "")}>
            <input
              type="password"
              className="spidle-input-text"
              placeholder="Confirm Password"
              name="confirmPassword"
              value={signUpDetails.confirmPassword}
              onChange={this.handleChange}
            />
            {this.state.confirmErr && (
              <div className="spidle-error-message help-block">
                Password and confirm are not same
              </div>
            )}
          </div>
          <div className="form-btn-group">
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Create an account
            </Button>
          </div>
        </div>
        {dataLoading && <Spinner />}
      </div>
    );
  }
}

export default SignUp;
