import React from "react";
import { Route, Redirect } from "react-router-dom";
import NavMenu from "../nav-menu";
import Header from "../header";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return localStorage.getItem("user") ? (
          <>
            <div>
            <NavMenu />
            </div>
            <div style={{ display: "flex",  flexDirection:"column", width:"100%" }}>
              
              <Header />
              <Component {...props} />
            </div>
          </>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
