import React from "react";
import PropTypes from "prop-types";
import "./spinner.scss";

/**
 *  Description: Creates an overlay spinner with size large, medium or small.
 *
 *  @param showLoader defaults to true
 *  @param className defaults to loader-fixed
 *  @param overlay defaults to true
 *  @param size defaults to large
 *  @param overlayHeight to fit the container
 *  @param zIndex for depth
 */
const Spinner = ({
  showLoader,
  className,
  overlay,
  size,
  overlayHeight,
  zIndex
}) => {
  const loaderStyle = showLoader ? { zIndex: zIndex } : { display: "none" };
  return (
    <div className="spinner-container">
      <div
        style={
          overlayHeight
            ? { height: overlayHeight + "px", zIndex: zIndex }
            : loaderStyle
        }
        className={`spinner-overlay ${
          overlay && showLoader ? "spinner-disable-click" : ""
        }`}
      />
      <div
        style={loaderStyle}
        className={`${className} spinner-${size} spinner`}
      />
    </div>
  );
};

Spinner.propTypes = {
  showLoader: PropTypes.bool,
  overlay: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  overlayHeight: PropTypes.number,
  zIndex: PropTypes.number
};

Spinner.defaultProps = {
  className: "loader-fixed",
  showLoader: true,
  overlay: true,
  size: "large",
  overlayHeight: null,
  zIndex: 55555
};

export default Spinner;
