export const getAudienceConfig = props => {
  return [
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Contact",
      accessor: "contact"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "amount spent",
      accessor: "total_amount_spent"
    },
    {
      Header: "Total orders",
      accessor: "total_orders"
    }
  ];
};
