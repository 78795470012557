import React from "react";
import SearchIcon from "../../images/magnifying-glass.svg";
import "./index.scss";

const SearchBox = ({ onChange, value }) => {
  return (
    <div className="cmr-search-box">
      <div>
        <img src={SearchIcon} className="icon" alt="searchicon" />
      </div>
      <input onChange={onChange} value={value} />
    </div>
  );
};

export default SearchBox;
