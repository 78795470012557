import React from "react";
import AppLogin from "./login";
import Dashboard from "./dashboard";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./private-route";
import SignUp from "./signup";
import Overview from "./overview";
import Campaign from "./campaign";
import Audience from "./audience";
class Home extends React.Component {
  render() {
    return (
      <Router>
        <div className="app-body-container">
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/" component={Overview} />
            <PrivateRoute exact path="/campaign" component={Campaign} />
            <PrivateRoute exact path="/audience" component={Audience} />

            <Route exact path="/login" component={AppLogin} />
            <Route exact path="/signup" component={SignUp} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Home;
