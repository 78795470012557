import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { Button } from "@material-ui/core";
import { invokeApi } from "../../services/dataServices";
import { getAudienceConfig } from "./config";
import "./index.scss";
import { SHOP_ENDPOINTS, DOMAIN_NAME } from "../../constants/endpoints";
import Spinner from "../../core-components/spinner";
import { CSVLink } from "react-csv";

const Audience = ({ audiences, tableConfig, downloadFlag }) => {
  const [audienceList, setAudienceList] = useState(audiences);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (audiences) {
      setAudienceList(audiences);
    } else {
      getAudienceList();
    }
  }, []);

  const getAudienceList = () => {
    setDataLoading(true);
    invokeApi(`${DOMAIN_NAME}${SHOP_ENDPOINTS.AUDIENCE}`, "POST")
      .then(data => {
        setAudienceList(data);
        setDataLoading(false);
      })
      .catch(err => {
        setDataLoading(false);
      });
  };

  return (
    <div className="audience-container">
      <div className="audience-header">
        <label className="title">Audience</label>
       {downloadFlag && <CSVLink
        filename="audience_list.csv"
          data={(audienceList || []).map(audience => {
            audience.last_visit = new Date(audience.last_visit * 1000);
            return audience;
          })}
        >
          <Button variant="contained" color="primary">
            Download audience
          </Button>
        </CSVLink>}
      </div>

      <div className="audience-list-container">
        <ReactTable
          sortable={true}
          multiSort={true}
          filterable={true}
          data={audienceList}
          defaultPageSize={10}
          minRows={10}
          columns={tableConfig || getAudienceConfig()}
        />
      </div>
      {dataLoading && <Spinner />}
    </div>
  );
};

export default Audience;
