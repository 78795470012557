import React from "react";

import "./index.scss";

const OverviewInfoCard = ({ icon, count, title }) => {
  return (
    <div className="info-card-container">
      <div>
        <img src={icon} alt={title} className="info-icon" />
      </div>
      <div className="info-count">{count}</div>
      <div className="info-title">{title}</div>
    </div>
  );
};

export default OverviewInfoCard;
