import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ReactTable from "react-table-6";
import { Button } from "@material-ui/core";
import CrmModal from "../../core-components/modal";
import Audience from "../audience";
import OverviewInfoCard from "./info-card";
import CAMPAIGN from "../../images/campaigns.svg";
import EMAIL from "../../images/email.svg";
import SMS from "../../images/sms.svg";
import TARGET_AUDIENCE from "../../images/target_audience.svg";
import { invokeApi } from "../../services/dataServices";
import { getCampaignListConfig, getCampAudienceConfig } from "./config";

import { SHOP_ENDPOINTS, DOMAIN_NAME } from "../../constants/endpoints";
import Spinner from "../../core-components/spinner";

import "./index.scss";
import SearchBox from "../../core-components/searchinput";
import { toast } from "react-toastify";
const Overview = () => {
  const [overviewDetails, setOverviewDetail] = useState({
    active_campaigns: 0,
    available_audience: 0,
    available_email_credits: 0,
    available_sms_credits: 0,
    total_campaigns: 0,
    total_email_credits: 0,
    total_sms_credits: 0,
    used_email_credits: 0,
    used_sms_credits: 0
  });
  const [campaignList, setCampaignList] = useState([]);
  const [campaignAudienceList, setCampaignAudienceList] = useState([]);
  const [openAudience, setOpenAudience] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const history = useHistory();
  useEffect(() => {
    getOverviewDetials();
    getCampaignList();
  }, []);

  const getOverviewDetials = () => {
    setDataLoading(true);
    invokeApi(`${DOMAIN_NAME}${SHOP_ENDPOINTS.OVERVIEW}`, "POST")
      .then(data => {
        setOverviewDetail(data);
        setDataLoading(false);
      })
      .catch(err => {
        setDataLoading(false);
      });
  };

  const getCampaignList = () => {
    setDataLoading(true);
    invokeApi(`${DOMAIN_NAME}${SHOP_ENDPOINTS.GET_CAMPAIGNS}`, "POST")
      .then(data => {
        if (data.campaigns) {
          setCampaignList(data.campaigns);
        }

        setDataLoading(false);
      })
      .catch(err => {
        setDataLoading(false);
      });
  };

  const viewAudience = campaign => {
    console.log("campaign", campaign);
    let campaignId = (campaign.original && campaign.original.id) || "";
    if (campaignId) {
      setDataLoading(true);
      invokeApi(
        `${DOMAIN_NAME}${SHOP_ENDPOINTS.GET_AUDIENCE_BY_ID}${campaignId}/`,
        "GET"
      )
        .then(data => {
          if (data) {
            setCampaignAudienceList(data);
            setOpenAudience(true)
          }
          setDataLoading(false);
        })
        .catch(err => {
          setDataLoading(false);
        });
    } else {
      toast.error("Missing audience details");
    }
  };

  const filterCampaigns = () => {
    if (searchKey !== "") {
      return campaignList.filter(
        campaign =>
          (campaign.name || "")
            .toLowerCase()
            .includes(searchKey.toLowerCase()) ||
          `${campaign.status || ""}`
            .toLowerCase()
            .includes(searchKey.toLowerCase())
      );
    } else {
      return campaignList;
    }
  };
  return (
    <div className="overview-container">
      <label className="title">Overview</label>
      <div className="overview-info-row">
        <OverviewInfoCard
          icon={CAMPAIGN}
          count={`${overviewDetails.active_campaigns || 0}/${overviewDetails.total_campaigns || 0}`}
          title={"Active Campaigns"}
        />
        <OverviewInfoCard
          icon={TARGET_AUDIENCE}
          count={`${overviewDetails.available_audience || 0}`}
          title={"Available Target Audience"}
        />
        <OverviewInfoCard
          icon={EMAIL}
          count={`${overviewDetails.available_email_credits || 0}/${overviewDetails.total_email_credits || 0} `}
          title={"Email credits"}
        />
        <OverviewInfoCard
          icon={SMS}
          count={`${overviewDetails.available_sms_credits || 0}/${overviewDetails.total_sms_credits || 0}`}
          title={"SMS credits"}
        />
      </div>
      <div className="compaign-list-container">
        <div className="list-header">
          <span className="table-title">Campaigns</span>
          <div className="list-left-container">
            <SearchBox
              value={searchKey}
              onChange={event => setSearchKey(event.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/campaign")}
            >
              Create campaign
            </Button>
          </div>
        </div>

        <div className="campaign-table">
          <ReactTable
            sortable={true}
            multiSort={true}
            filterable={false}
            data={filterCampaigns()}
            defaultPageSize={10}
            minRows={10}
            columns={getCampaignListConfig(viewAudience)}
          />
        </div>
      </div>
      {dataLoading && <Spinner />}
      {openAudience && (
        <CrmModal title="" onClose={() => setOpenAudience(false)}>
          <Audience audiences={campaignAudienceList} tableConfig={getCampAudienceConfig()} downloadFlag={true}/>
        </CrmModal>
      )}
    </div>
  );
};

export default Overview;
