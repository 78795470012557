import React from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import LOGO from "../../images/logo.svg";
import "./login.scss";
import { SHOP_ENDPOINTS, DOMAIN_NAME } from "../../constants/endpoints";
import Spinner from "../../core-components/spinner";

class AppLogin extends React.Component {
  constructor(props) {
    super(props);
    // reset login status
    this.state = {
      username: "",
      password: "",
      submitted: false,
      redirectUrl: "",
      pin: "",
      loggingIn: false,
      requiredPin: false,
      erroFlag: false,
      errorMsg: ""
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  login = () => {
    const { username, password, pin } = this.state;
    let reqBody = {
      email: username,
      password: password,
      pin: pin
    };
    const headers = { "Content-Type": "application/json" };

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(reqBody)
    };
    this.setState({ loggingIn: true });
    fetch(`${DOMAIN_NAME}${SHOP_ENDPOINTS.LOGIN}`, requestOptions)
      .then(response => response.json())
      .then(user => {
        if (user.code) {
          this.setState({ errorMsg: user.message, loggingIn: false });
        } else {
          localStorage.setItem("user", JSON.stringify(user.data));
        }
        this.setState({
          requiredPin: false,
          loggingIn: false,
          redirectUrl: user.data.restaurant_id ? "/" : "/setup"
        });
      })
      .catch(() => this.setState({ loggingIn: false }));
  };

  render() {
    const {
      username,
      password,
      submitted,
      redirectUrl,
      erroFlag,
      errorMsg,
      loggingIn,
      pin
    } = this.state;

    if (redirectUrl === "/" || redirectUrl === "/signup") {
      return <Redirect push to={`${redirectUrl}`} />;
    }

    return (
      <div className="login-container">
        <div className="login-header-row">
          <img className="login-logo" src={LOGO} alt="app logo" />
          <span className="version">V1.0.0</span>
        </div>
        <div className="login-body">
          <div className="login-form">
            <div className="title-conatiner">
              <h3 className="title">Login</h3>
              <span>
                {/* <Link to="/signup">Create an account</Link> */}
              </span>
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Email Id"
                className="spidle-input-text"
                name="username"
                value={username}
                onChange={this.handleChange}
              />
            </div>
            <div
              className={
                "form-group" + (submitted && !password ? " has-error" : "")
              }
            >
              <input
                placeholder="Password"
                type="password"
                className="spidle-input-text"
                name="password"
                value={password}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="PIN"
                className="spidle-input-text"
                name="pin"
                value={pin}
                onChange={this.handleChange}
              />
              {submitted && !username && (
                <div className="help-block">Username is required</div>
              )}
              {erroFlag && (
                <div className="help-block">
                  invalid user name or password or pin
                </div>
              )}
              <div className="spidle-error-message pt-3">{errorMsg}</div>
            </div>

            <div className="form-btn-group">
              <Button variant="contained" color="primary" onClick={this.login}>
                Login
              </Button>
            </div>
          </div>
        </div>
        {loggingIn && <Spinner/>}
      </div>
    );
  }
}

export default AppLogin;
