export const setOfflineData = (key, data) => {
  if (data !== "" && data !== null && data !== undefined) {
    localStorage.setItem(`spideoffline:${key}`, JSON.stringify(data));
  }
};

export const getOfflineData = key => {
  const data = localStorage.getItem(`spideoffline:${key}`);
  if (data !== "" && data !== null && data !== undefined) {
    return JSON.parse(data);
  } else {
    return "";
  }
};

export const clearOffllineData = (key, data) => {
  localStorage.removeItem(`spideoffline:${key}`);
};

export const clearSpidleOfflineData = startsWith => {
  let myLength = startsWith.length;
  Object.keys(localStorage).forEach(function(key) {
    if (
      key.substring(0, myLength) === startsWith ||
      key.substring(0, "otherCharges".length) === "otherCharges"
    ) {
      localStorage.removeItem(key);
    }
  });
};
