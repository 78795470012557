import React from "react";
import PropTypes from "prop-types";

import close from "../../images/ico_cross.svg";

import "./index.scss";

const CrmModal = ({ title, children, onClose, className }) => {
  return (
    <div className={`${className} crm-modal-container`}>
      <div className="modal-content">
        <div className="modal-header">
          <span className="modal-title">{title}</span>
          {onClose && (
            <span className="close" onClick={onClose}>
              <img src={close} alt="close" />
            </span>
          )}
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

CrmModal.defaultProps = {};
CrmModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default CrmModal;
