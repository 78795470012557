export function authHeader() {
  // return authorization header with jwt token
  let user = localStorage.getItem("user");
  if (user && user !== "undefined") {
    return "Bearer " + JSON.parse(user).access_token;
  } else {
    return "";
  }
}

export function getApplicationToken() {
  let spidleAppToke = localStorage.getItem("spidleAppToke");
  if (spidleAppToke) {
    return "Bearer " + JSON.parse(spidleAppToke).application_token;
  } else {
    return "";
  }
}

export function getRestaurantId() {
  let spidleAppToke = localStorage.getItem("spidleAppToke");
  if (spidleAppToke) {
    return JSON.parse(spidleAppToke).restaurant_id;
  } else {
    return "";
  }
}
