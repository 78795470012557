import React from "react";
import { Provider } from "react-redux";
import { store } from "./store.js";

import { ToastContainer } from "react-toastify";
import { ProgressBar } from "react-fetch-progressbar";
import { ThemeProvider } from "@material-ui/core/styles";

import Home from "./components/home";
import Theme from "./services/theme-customizations";
import "react-table-6/react-table.css";
import "./App.scss";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Provider store={store}>
        <ProgressBar style={{ backgroundColor: "#1755F4", height: "3px" }} />
        <ToastContainer
          className="toast-container"
          toastClassName="dark-toast"
          autoClose={5000}
        />
        <Home />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
