import { createMuiTheme } from "@material-ui/core/styles";

const Theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#1E266D"
    }
  },
  shape: {},
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "RobotoCondensed-Bold, arial",
        borderRadius: "0"
      },
      label: {
        fontWeight: "bold"
      }
    },
    MuiCheckbox: {
      root: {
        borderRadius: "0",
        color: "#33CCCC"
      },
      colorPrimary: {
        color: "#33CCCC"
      }
    },
    MuiChip: {
      root: {
        borderRadius: "0",
        textTransform: "uppercase"
      },
      labelSmall: {
        fontFamily: "RobotoCondensed-Bold"
      }
    },
    MuiSwitch: {
      thumb: {
        borderRadius: "0"
      },
      track: {
        borderRadius: "0"
      }
    },
    MuiMenuItem: {
      root: {
        borderRadius: "0",
        fontFamily: "RobotoCondensed-Bold"
      }
    },
    MuiFormControl: {
      root: {
        marginTop: 15
      }
    },
    MuiInputLabel: {
      formControl: {
        marginTop: -10
      }
    },
    MuiInput: {
      root: {
        border: 1,
        borderColor: "red"
      },
      input: {
        border: 1,
        borderColor: "red"
      }
    }
  }
});

export default Theme;
