import React, { useState, useEffect } from "react";
import Select from "react-select";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";

import { TextField, Checkbox, Button } from "@material-ui/core";

import { SHOP_ENDPOINTS, DOMAIN_NAME } from "../../constants/endpoints";
import Spinner from "../../core-components/spinner";
import { invokeApi } from "../../services/dataServices";
import CrmModal from "../../core-components/modal";
import Audience from "../audience";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss";
import { toast } from "react-toastify";
import { spidelDateFormat } from "../../utils";

const AUDIENCE_TYPES = [
  {
    label: "All",
    fieldName: "all"
  },
  {
    label: "Repeat Customers",
    fieldName: "repeat_customer"
  },
  {
    label: "Online Customers",
    fieldName: "online_customer"
  },
  {
    label: "Customer with purchases",
    fieldName: "amount_required",
    inputtype: "number"
  },
  {
    label: "Customer who visited in",
    fieldName: "time_range_required",
    inputtype: "date"
  }
];
const defaultOption = {
  value: ">=",
  label: "Greater Than & Equal to"
};
const options = [
  {
    value: ">=",
    label: "Greater Than & Equal to"
  },
  {
    value: "=",
    label: "Equal to"
  },
  {
    value: "<=",
    label: "Less Than & Equal to"
  },
  {
    value: ">",
    label: "Greater Than"
  },
  {
    value: "<",
    label: "Less Than"
  }
];

const TimeRangeOptions = [
  {
    value: ">",
    label: "Visited After"
  },
  {
    value: "<",
    label: "Visited Before"
  }
];

const Campaign = () => {
  const [audienceList, setAudienceList] = useState([]);
  const [openAudience, setOpenAudience] = useState(false);
  const [openCustomize, setOpenCustomize] = useState(false);
  const [openDateCustomize, setOpenDateCustomize] = useState(false);
  const [smsText, setsmsText] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [selectedOperator, setSelectedOperator] = useState(defaultOption);
  const [selectedDummyOperator, setSelectedDummyOperator] = useState(
    defaultOption
  );

  const [selectedDateOperator, setSelectedDateOperator] = useState(
    TimeRangeOptions[0]
  );
  const [selectedDummyDateOperator, setSelectedDummyDateOperator] = useState(
    TimeRangeOptions[0]
  );

  const [selectedAudienceType, setSelectedAudienceType] = useState({
    all: true,
    repeat_customer: true,
    online_customer: true,
    amount_required: true,
    time_range_required: true
  });
  const [dataLoading, setDataLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [dummyAmount, setDummyAmount] = useState("");
  const [timeRange, setTimeRangeDate] = useState(new Date());
  const [dummyTimeRange, setDummyTimeRangeDate] = useState(new Date());

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const editor = React.useRef(null);

  useEffect(() => {
    getAudience();
  }, []);

  const getAudience = () => {
    setDataLoading(true);
    invokeApi(`${DOMAIN_NAME}${SHOP_ENDPOINTS.AUDIENCE}`, "POST")
      .then(data => {
        setAudienceList(data);
        setDataLoading(false);
      })
      .catch(err => {
        setDataLoading(false);
      });
  };

  const saveCampaign = deliveryType => {
    const payload = {
      campaign_type: 1,
      content_delivery_type: deliveryType,
      static_campaign_customer_params: {
        all: selectedAudienceType.all,
        repeat_customer: selectedAudienceType.repeat_customer,
        online_customer: selectedAudienceType.online_customer,
        amount_based: {
          amount: +amount,
          op: (selectedOperator && selectedOperator.value) || ">=",
          required: selectedAudienceType.amount_required
        },
        time_range: {
          timestamp: Math.round(new Date(timeRange).getTime() / 1000),
          op: (selectedDateOperator && selectedDateOperator.value) || ">",
          required: selectedAudienceType.time_range_required
        }
      }
    };

    if (deliveryType === 1) {
      const emailHTMLContent = stateToHTML(editorState.getCurrentContent());
      payload["content"] = emailHTMLContent;
      payload["email_sub"] = emailSubject;
    } else {
      payload["content"] = smsText;
    }

    invokeApi(`${DOMAIN_NAME}${SHOP_ENDPOINTS.SAVE_CAMPAIGN}`, "POST", payload)
      .then(data => {
        if (data.error) {
          toast.error("Campaign failed, please contact support");
        } else {
          if (data.message) {
            toast.info(data.message);
          } else {
            toast.info("Campaign created");
          }

          if (deliveryType === 1) {
            setEmailSubject("");
            
            setEditorState(EditorState.createEmpty());
          } else {
            setsmsText("");
          }
          setDataLoading(false);
        }
      })
      .catch(err => {
        toast.info("Campaign failed");
        setDataLoading(false);
      });
  };

  const toggleCheckBox = audience => {
    let newState = {};
    if (audience.fieldName === "all") {
      newState = {
        all: !selectedAudienceType[audience.fieldName],
        repeat_customer: !selectedAudienceType[audience.fieldName],
        online_customer: !selectedAudienceType[audience.fieldName],
        amount_required: !selectedAudienceType[audience.fieldName],
        time_range_required: !selectedAudienceType[audience.fieldName]
      };
    } else {
      newState = {
        ...selectedAudienceType,
        [audience.fieldName]: !selectedAudienceType[audience.fieldName]
      };
      if (
        newState.repeat_customer &&
        newState.online_customer &&
        newState.amount_required &&
        newState.time_range_required
      ) {
        newState["all"] = true;
      } else {
        newState["all"] = false;
      }
    }

    setSelectedAudienceType(newState);
  };

  const getFilteredAudience = () => {
    let filteredAudience = [...audienceList];
    if (selectedAudienceType["all"]) {
      return audienceList;
    }

    if (
      !selectedAudienceType.repeat_customer &&
      !selectedAudienceType.online_customer &&
      !selectedAudienceType.amount_required &&
      !selectedAudienceType.time_range_required
    ) {
      return [];
    }

    if (selectedAudienceType["amount_required"]) {
      filteredAudience.forEach(audiance => {
        if (
          (selectedOperator.value === "=" &&
            audiance.total_amount_spent === amount) ||
          (selectedOperator.value === ">" &&
            audiance.total_amount_spent > amount) ||
          (selectedOperator.value === "<" &&
            audiance.total_amount_spent < amount) ||
          (selectedOperator.value === ">=" &&
            audiance.total_amount_spent >= amount) ||
          (selectedOperator.value === "<=" &&
            audiance.total_amount_spent <= amount)
        ) {
          audiance["amount_required"] = true;
        } else {
          audiance["amount_required"] = false;
        }
      });
    } else {
      filteredAudience.forEach(audiance => {
        audiance["amount_required"] = false;
      });
    }

    if (selectedAudienceType["repeat_customer"]) {
      filteredAudience.forEach(audiance => {
        if (audiance.total_orders > 1) {
          audiance["repeat_customer"] = true;
        } else {
          audiance["repeat_customer"] = false;
        }
      });
    } else {
      filteredAudience.forEach(audiance => {
        audiance["repeat_customer"] = false;
      });
    }

    if (selectedAudienceType["time_range_required"]) {
      filteredAudience.forEach(audiance => {
        if (selectedDateOperator && selectedDateOperator.value === "<") {
          if (audiance.last_visit * 1000 < new Date(timeRange).getTime()) {
            audiance["time_range_required"] = true;
          } else {
            audiance["time_range_required"] = false;
          }
        } else if (selectedDateOperator && selectedDateOperator.value === ">") {
          if (audiance.last_visit * 1000 > new Date(timeRange).getTime()) {
            audiance["time_range_required"] = true;
          } else {
            audiance["time_range_required"] = false;
          }
        }
      });
    } else {
      filteredAudience.forEach(audiance => {
        audiance["time_range_required"] = false;
      });
    }

    if (selectedAudienceType["online_customer"]) {
      filteredAudience.forEach(audiance => {
        if (audiance.type === "online") {
          audiance["online_customer"] = true;
        } else {
          audiance["online_customer"] = false;
        }
      });
    } else {
      filteredAudience.forEach(audiance => {
        audiance["online_customer"] = false;
      });
    }

    return filteredAudience.filter(audiance => {
      return (
        audiance["online_customer"] ||
        audiance["time_range_required"] ||
        audiance["repeat_customer"] ||
        audiance["amount_required"]
      );
    });
  };

  return (
    <div className="campaign-container">
      <label className="title">Campaign</label>
      <div className="campaign-body">
        <div className="left-container">
          <div className="label">Target Audience</div>
          <div className="campaign-options">
            {AUDIENCE_TYPES.map(audience => (
              <div className="campain-option" key={audience.label}>
                <span>{audience.label}</span>
                <div>
                  {selectedAudienceType[audience.fieldName] &&
                    !selectedAudienceType["all"] && (
                      <>
                        {audience.inputtype === "number" && (
                          <>
                            <span
                              className="customize-link"
                              onClick={() => {
                                setOpenCustomize(true);
                                setSelectedDummyOperator(selectedOperator);
                                setDummyAmount(amount);
                              }}
                            >
                              Customize
                            </span>
                            <span className="customize-info">{` (${
                              selectedOperator.value
                            } ${amount || "0"})`}</span>
                          </>
                        )}
                        {audience.inputtype === "date" && (
                          <>
                            <span
                              className="customize-link"
                              onClick={() => {
                                setOpenDateCustomize(true);
                                setSelectedDummyOperator(selectedOperator);
                                setDummyAmount(amount);
                              }}
                            >
                              Customize
                            </span>
                            <span className="customize-info">{` (${
                              selectedDateOperator.value
                            } ${spidelDateFormat(timeRange)})`}</span>
                          </>
                        )}
                      </>
                    )}
                  <Checkbox
                    value="secondary"
                    color="primary"
                    checked={selectedAudienceType[audience.fieldName]}
                    onClick={() => toggleCheckBox(audience)}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </div>
            ))}
            <div className="total-audiance-container">
              <span>Total Audience:</span>
              <span>{getFilteredAudience().length}</span>
              <span
                className="view-all-audience"
                onClick={() => setOpenAudience(true)}
              >
                View all
              </span>
            </div>
          </div>
          <div className="campaign-sms-container">
            <label className="sms-title">
              {`SMS Block (${smsText.length}/160 Chars)`}
            </label>
            <div>
              <textarea
                className="sms-text-area"
                placeholder="Type Message"
                value={smsText}
                onChange={event => setsmsText(event.target.value)}
              />
            </div>
          </div>
          <div className="form-btn-group">
            <Button
              disabled={smsText.length > 160 || smsText.length === 0}
              variant="contained"
              color="primary"
              onClick={() => saveCampaign(2)}
            >
              Create sms campaign
            </Button>
          </div>
        </div>
        <div className="right-container">
          <label className="email-title">Email Block</label>
          <input
            className="email-subject"
            placeholder="Subject Line"
            value={emailSubject}
            onChange={event => setEmailSubject(event.target.value)}
          />
          <Editor
            ref={editor}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="send-campaign-editor1"
            editorClassName="send-campaign-editor"
            onEditorStateChange={editorState => setEditorState(editorState)}
          />

          <div className="form-btn-group send-email-btn">
            <Button
              disabled={emailSubject.length === 0}
              variant="contained"
              color="primary"
              onClick={() => saveCampaign(1)}
            >
              Create email campaign
            </Button>
          </div>
        </div>
      </div>
      {dataLoading && <Spinner />}
      {openAudience && (
        <CrmModal title="" onClose={() => setOpenAudience(false)}>
          <Audience audiences={getFilteredAudience} />
        </CrmModal>
      )}
      {openCustomize && (
        <CrmModal
          className="chose-operator-modal"
          title="Choose Operator"
          onClose={() => {
            setOpenCustomize(false);
            // setSelectedOperator(defaultOption);
          }}
        >
          <div className="chose-operator">
            <Select
              className="amount-operator"
              value={selectedDummyOperator}
              onChange={value => setSelectedDummyOperator(value)}
              options={options}
            />
            <input
              className="amount-input"
              type="number"
              value={dummyAmount}
              onChange={event => setDummyAmount(+event.target.value)}
            />
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenCustomize(false);
                setSelectedOperator(selectedDummyOperator);
                setAmount(dummyAmount);
              }}
            >
              Submit
            </Button>
          </div>
        </CrmModal>
      )}

      {openDateCustomize && (
        <CrmModal
          className="chose-operator-modal"
          title="Choose Operator"
          onClose={() => {
            setOpenDateCustomize(false);
            // setSelectedOperator(defaultOption);
          }}
        >
          <div className="chose-operator">
            <Select
              className="amount-operator"
              value={selectedDummyDateOperator}
              onChange={value => setSelectedDummyDateOperator(value)}
              options={TimeRangeOptions}
            />
            <TextField
              id="date"
              type="date"
              value={dummyTimeRange}
              onChange={data => setDummyTimeRangeDate(data.target.value)}
            />
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenDateCustomize(false);
                setSelectedDateOperator(selectedDummyDateOperator);
                setTimeRangeDate(dummyTimeRange);
              }}
            >
              Submit
            </Button>
          </div>
        </CrmModal>
      )}
    </div>
  );
};

export default Campaign;
