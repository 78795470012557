import React from "react";
export const getCampaignListConfig = viewAudience => {
  return [
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
      defaultSortDesc: true,
      Cell: props => <span className="campaign-name">{props.value}</span>
    },
    {
      Header: "Audience",
      accessor:"id",
      Cell: props => <span className="view-audience-list" onClick={()=>viewAudience(props)}>View Audience</span>
    },
    {
      Header: "Audience Count",
      accessor: "target_audience"
    },
    {
      Header: "Email sent",
      accessor: "email_sent"
    },
    {
      Header: "SMS sent",
      accessor: "sms_sent"
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: props => (
        <span className={`${(`${props.value} || ""`).toLowerCase()} status-cell`}>
          {props.value}
        </span>
      )
    }
  ];
};


export const getCampAudienceConfig = props => {
  return [
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Contact",
      accessor: "contact"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "amount spent",
      accessor: "total_amount_spent"
    },
    {
      Header: "Email sent",
      accessor: "email_sent"
    },
    {
      Header: "SMS sent",
      accessor: "sms_sent"
    }
  ];
};
