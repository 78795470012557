export const SHOP_ENDPOINTS = {
  LOGIN: "user_services/users/login/",
  AUDIENCE: "notification/crm/audience/",
  SEND_EMAIL: "notification/crm/send-email/",
  SEND_SMS: "notification/crm/send-sms/",
  OVERVIEW: "notification/crm/overview/",
  GET_CAMPAIGNS: "notification/crm/current-campaigns/",
  SAVE_CAMPAIGN: "notification/crm/campaign/save/",
  GET_AUDIENCE_BY_ID: "notification/crm/campaign/audience/"
};

const STAGING_DOMAIN_NAME = "https://dev01.api.spidlepos.com/";
const PRODUCTION_DOMAIN_NAME = "https://api.spidlepos.com/";

export const DOMAIN_NAME =
  process.env.REACT_APP_ENV === "production"
    ? PRODUCTION_DOMAIN_NAME
    : STAGING_DOMAIN_NAME;
