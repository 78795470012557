import { authHeader } from "../helpers";
import { post } from "axios";
import { getOfflineData } from "../utils/offlineService";

export const invokeOffline = (url, method, offlineKey, headers) => {
  let body;
  headers = new Headers({
    ['Content-Type']:"application/json",
    Authorization: authHeader()
  });
  const offlineData = getOfflineData(offlineKey);
  if (offlineData) {
    return Promise.resolve({ data: offlineData });
  }

  return fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: headers
  }).then(response => {
    return response.json();
  });
};

export const invokeUploadFile = (url, formData) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: authHeader()
    }
  };
  return post(url, formData, config);
};

export const invokeApi = (url, method, data, params, headers) => {
  let token = authHeader();
  headers = new Headers({
    ['Content-Type']:"application/json",
    Authorization: token
  });
  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: headers
  }).then(response => {
    if (response.ok && response.status === 500) {
      console.log("error api " + response);
    }
    return response.json();
  });
};

export const invokeDownloadFile = (url, method, data, params, headers) => {
  headers = new Headers({
    Authorization: authHeader()
  });
  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: headers
  }).then(response => response.blob());
};
